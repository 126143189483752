$green-field: #54bd3f;
$green-button-raised: #8CC748;
$green-button-flat: #7CB342;
$black-54: rgba(0, 0, 0, .54);

.mat {
  &-button, &-raised-button, &-stroked-button, &-flat-button {
    text-transform: uppercase;
  }

  &-full-width {
    width: 100%;
  }

  &-card {
    &.mat-card-forms {
      padding: 24px;
      border-radius: 2px;
      width: 384px;
      margin: 0 auto;
      &:not([class*=mat-elevation-z]) {
        -webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
        -moz-box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
        box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
      }
      @media screen and (max-width: 384px) {
        width: 100%;
      }
    }
  }
  &-primary.mat-primary-submit {
    color: $green-button-flat;
  }
  &-raised-button {
    &.mat-primary.mat-primary-submit {
      background-color: $green-button-raised;
      color: #ffffff;
      &[disabled] {
        background-color: $green-button-raised;
        color: rgba(255, 255, 255, .5);
      }
    }
  }
  &-form-field-appearance-legacy {
    .mat-form-field-suffix .mat-icon-button {
      width: 24px;
      height: 24px;

      mat-icon.mat-icon {
        font-size: 24px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        color: $black-54;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 18px;
    }
  }
  &-checkbox-auth {
    .mat-checkbox-inner-container {
      width: 18px;
      height: 18px;
    }
    .mat-checkbox-frame {
      border-radius: 3px;
    }
    .mat-checkbox-layout {
      white-space: normal;
    }
    .mat-checkbox-label {
      line-height: 20px;
      font-size: 12px;
    }
    &-error {
      .mat-checkbox-frame {
        border-color: #ff1744;
      }
    }
  }
}

