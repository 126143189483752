/* You can add global styles to this file, and also import other style files */

@import "md-override";

$text-color: rgba(0, 0, 0, .87);

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: $text-color;
  background-color: #f2f2f2;
  h1.header-h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  h1.form-h1 {
    font-size: 18px;
    margin: 0;
    line-height: 20px;
  }
  .mat-form-field {
    line-height: 1.4;
  }
}
.mainwrap {
  min-height: 100%;
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.form {
  margin-bottom: 24px;
  &-wrap {
    margin: auto;
    &.rtl {
      direction: rtl;
      .mat-form-field-appearance-legacy .mat-form-field-infix {
        text-align: right;
      }
      .mat-form-field {
        text-align: right;
      }
      .buttons-block {
        text-align: left;
      }
      .mat-checkbox-inner-container {
        margin-right: auto;
        margin-left: 8px;
      }
      .registration-form-auth {
        .mat-checkbox-inner-container {
          margin: 0 0 auto 12px;
        }
      }
      .login-form-remember {
        padding-right: 0;
        padding-left: 12px;
      }
    }
  }
  &-error {
    background-color: #ffcdd2;
    padding: 12px 24px;
    transition: all 1s ease-out 0.3s;
    width: 384px;
    margin: 0 auto;
    @media screen and (max-width: 384px) {
      width: 100%;
    }
  }
  &-text {
    margin-bottom: 28px;
  }
  &-logo {
    margin-bottom: 24px;
    &-img {
      display: block;
    }
  }
  &-title {
    padding: 0 0 24px;
    &-text {
      padding: 24px 0 0;
      font-size: 12px;
    }
  }
  &-link {
    font-size: 12px;
    &-wrap {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  &-field {
    margin-bottom: 14px;
  }
  &-hint {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 20px;
  }
  &-lang {
    width: 384px;
    margin: 0 auto;
    .mat-form-field-appearance-legacy {
      .mat-form-field {
        &-wrapper {
          padding-bottom: 0;
          font-size: 12px;
        }
        &-underline {
          display: none;
        }
        &-infix {
          width: auto;
          border-top: 0;
          padding: 8px 0 0;
        }
      }
      .mat-select-arrow {
        border-left-width: 4px;
        border-right-width: 4px;
        border-top-width: 4px;
        margin: -2px 8px 0;
      }
    }
    @media screen and (max-width: 384px) {
      width: 100%;
    }
  }
}

.buttons-block {
  text-align: right;
  &-bottom {
    margin: 0 -24px -24px;
    padding: 8px 8px 8px 0;
  }
  .mat-raised-button {
    border-radius: 2px;
  }
}

.link {
  &:link, &:visited, &:hover {
    color: #2196f3;
    text-decoration: none;
  }
}

/**
  Writing this style as global to avoid encapsulation of Material elements
 */
.registration-form-auth {
  .mat-checkbox-inner-container {
    margin: 0 12px auto 0;
  }
}
